.gap {
    width: 100%;
    height: 850px;
    background-color: white;
}

.gap h2 {
    font-weight: bold;
    color: black;
}

.heading {
    padding-bottom: 40px;
    text-align: center;
}

.heading img {
    padding-bottom: 24px;
}

.heading p {
    font-size: 18px;
    line-height: 30px;
    color: #555;
    margin: 0;
    font-weight: 400;
    font-family: 'Ubuntu Sans';
}

.slick-dots {
    bottom: -80px !important;
}

.slider-container {
    /* display: flex;
    justify-content: center; */
}

.slider-content {
    color: red;
    margin: 0 auto;
    /* width: 1400px; */
    width: 100%;

}