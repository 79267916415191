.head_purp {
    background-color: #672b83;
    color: white;
}

.con_purp {

}

body{
    background-color: #f4f2f7;
}