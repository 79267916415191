@media (max-width: 780px) {
    .test-container {
        flex-direction: column;
    }

    .answer-options li {
        margin-top: 10px;
    }

    .c1,
    .c2 {
        width: 100%;
    }

    .horizontal {
        height: 250px;
    }

    .horizontal img {
        width: 100%;
    }


    .vertical img {
        height: 100%;
    }
}