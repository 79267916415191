@media (max-width: 479px) {
   .instructions {
      font-size: 14px;
   }

   .word-container,
   .drop-zones,
   .zone-title {
      font-size: 10px;
   }

   .draggableWord {
      padding: 4px 7.5px;
   }
}