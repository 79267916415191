.feedback {
    display: inline;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.feedback.correct {
    background-color: lightblue;
    color: darkblue;
}
.feedback.incorrect {
    background-color: black;
    color: white;
}