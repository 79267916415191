@media (max-width: 1200px) {

    .header {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .header .container {
        max-width: 960px;
    }

    .purple-bg .top-bar,
    .nav-bar {
        display: none !important;
    }

    .purple-bg .center-ber {
        margin-top: 0;
        padding: 0;
    }

    .header .center-ber .login-bar,
    .header form {
        display: none !important;
    }

    .bar-menu {
        display: block;
    }

    .mobile_h {
        display: block;
    }


}