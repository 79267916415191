@media (min-width: 320px) and (max-width: 479px) {
    .offcanvas {
        width: 100% !important;
    }
}

@media (min-width: 480px) and (max-width: 575px) {
    .offcanvas {
        width: 40% !important;
        display: flex;
        justify-content: center;
    }

    .mobile-logoH {
        width: auto;
        scale: 0.65;
    }

    .offcanvas button {

    }

    .offcanvas .btn-close {
        right: 0;
    }
}