.classContainer {
    width: 400px;
    margin-left: 30px;
    margin-top: 15px;
}

.list-item {
    margin-bottom: 10px;
}

@media (max-width: 320px) {
    .classContainer {
        width: 260px;
    }
}