@media (max-width: 1200px) {

    .slider img {
       padding: 0;
       scale: 0.9;
    }
}

@media (max-width: 1024px) {

    .slider img {
        margin: 0 !important;
        padding: 0 !important;
        /* width: 100% !important; */
        height: auto !important;
        scale: 0.8;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .slider img {
        margin: 0 !important;
        padding: 0 !important;
        height: auto !important;
        scale: 0.7;
    }
}

@media (min-width: 480px) and (max-width: 767px) {

    .carousel_bg {
        /* height: 500px; TODO: якщо ми хочемо зробити бекграунд слайдера меншим по висоті*/
    }

    .custom-controls {
        display: none;
      }

    .slider {
        height: 90%;
    }

    .slider img {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        height: auto !important;
        scale: 0.6;
    }
}

@media (max-width: 479px) {

    .carousel_bg {
        height: 450px;
    }

    .custom-controls {
        display: none;
      }

    .slider {
        height: 90%;
    }

    .slider img {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        height: auto !important;
        scale: 0.6;
    }
}