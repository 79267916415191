@media (max-width: 1200px) {
    html,body {
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;
    }
}

/* @media(max-width: 1400px){
	h2 {
    font-size: 46px;
	}
	.error{
    width: 58%;
  }
	.text {
    font-size: 20px	;
  }
  .call-us.two {
    margin-top: -50px;
	}
  .error-section {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .account ul li a {
    font-size: 14px;
	}
  nav.navbar a.phone {
    padding-top: 0px;
	}
  .top-bar p,.footer-bottom p {
    font-size: 16px;
	}
	.party-accessories {
    padding: 20px 20px;
  }
	.theme-text .star {
    padding-bottom: 0px;
	}
	.slider-home-1 .owl-dot:before {
    padding-top: 0px;
	}
	.theme-text.party-balloons.two .star {
    padding-bottom: 14px !important;
	}
	.theme-text.two > span {
    padding-bottom: 18px;
	}
	.out-of {
    padding-top: 10px;
	}
	footer.gap {
    padding-bottom: 20px;
	}
	.review-style {
    margin-bottom: -23px;
	}
	.products .nav-link {
    width: 50px;
    height: 50px;
  }
 	.products .nice-select.Advice {
    height: 50px;
    padding-top: 3px;
    margin-left: 20px;
	} 
	.products .nice-select.Advice:before {
    width: 36px;
    height: 36px;
    right: 7px;
    top: 6px;
	}
	.products svg {
    width: 20px;
  }
  .accordion {
    gap: 10px;
	}
	.ready-time h3 {
    font-size: 28px;
  }
	nav.navbar > a {
    display: flex;
    align-items: center;
	}
	.holiday-text-style span {
    padding-top: 6px;
  }
  .party-balloons h4 {
    font-size: 34px;
  }
  .occasion-details .theme-text.party-balloons {
    width: 93%;
	}
  .holiday-text-style i {
    font-size: 24px;
    padding-top: 34px;
	}
	.slider-home-2.owl-carousel .owl-item img{
    width: 100%;
	}
	.slider-home-1 .owl-dots {
    position: absolute;
    top: 86%;
    right: 7%;
	}
	.heading.two {
    padding-bottom: 20px;
	}
	.theme-text {
    width: 90%;
    margin-left: auto;
	}
	.navbar-links li > a {
    font-size: 16px;
	}
	.slider-home-1 .owl-dot:after {
    width: 10px;
    height: 4px;
    bottom: -2px;
    left: 16px;
	}
	.btn {
    padding: 11px 50px;
  }
	li.navbar-dropdown.menu-item-children img {
    width: 14px;
    margin-bottom: 4px;
	}
	.navbar-links li > a i {
    padding-right: 4px;
	}
	.slider-home-1 .owl-dot:before {
    width: 40px;
    height: 40px;
  }
	section.gap.weekly-section {
    padding-top: 120px;
	}
	.navbar-links li.navbar-dropdown .dropdown a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
  }
	.we-provide i:after {
    width: 15px;
    height: 5px;
    bottom: -3px;
	}
	.navbar-links li.navbar-dropdown:hover .dropdown {
    padding-bottom: 10px;
	}
	.weekly-sellers-img img,.pd-gallery.two .pd-main-img img {
    width: 100%;
	}
	.holiday-text-style {
    width: 90%;
  }
  .holiday-text-style p {
    padding-bottom: 20px;
	}
	img.p-balloon {
    bottom: 5px;
    left: -90px;
    width: 50%;
	}
	.navbar-links li {
    padding: 20px 30px;
    padding-left: 0;
    padding-right: 50px;
	}
	.featured-area h5 {
    font-size: 20px;
  }
  .hero-section-two::before {
    height: 600px;
  }
	.similar-occasion img {
    width: 100%;
	}	
	.product-info {
    padding: 40px;
  }
  .products #v-pills-tab {
    margin-left: 20px;
	}
  .pd-main-img {
    height: 508px;
  }
  .center-ber form input {
    width: 580px;
 	}
  .featured-area:before {
    width: 100px;
    height: 40px;
  }
	.holidays-text {
    padding: 0px 70px;
    padding-right: 0;
	}
	.occians-holidays.for:after {
    top: 99%;
	}
	.posts {
    padding: 30px;
  }
  .occasion > img {
    width: 52%;
    border-bottom: 20px;
	}
	.occasion .theme-text {
    width: 43%;
    margin-left: 40px;
	}
	.pd-main-img img {
    width: 300px;
	}
  .store-locations-text {
    padding: 40px;
	}
  .blog-img-video.video img {
    width: 100%;
	}
	.recent-posts li h6 a {
    font-size: 16px;
    line-height: 22px;
	}
	.theme-text h3 {
    padding-bottom: 10px;
	}
	.theme-text ul li {
    font-size: 16px;
    margin-bottom: 10px;
	}
	.team-text img {
    width: 100%;
	}
	.fun-facts-two {
    height: 220px;
    width: 220px;
  }
  .fun-facts-two h6 {
    font-size: 16px;
	}
	.fun-facts-two:before {
    left: 84px;
	}
	.posts ul.instagram-posts img {
    width: 90px;
    height: 90px;
	}
	.title {
    font-size: 18px;
	}
	.accordion-item .icon {
    width: 2.0rem;
    height: 2.0rem;
  }
  ul.instagram-posts img {
    height: 154px;
    width: 154px;
  }
	.occians-holidays:before {
    width: 356px;
    height: 356px;
  }
  .occians-holidays:after {
    width: 53px;
    left: 50%;
    top: 96%;
  }
  .occians-holidays figure:before {
    width: 265px;
    height: 265px;
    left: 12.5%;
    top: 3%;
	}	
	.recent-product-two-img img {
    width: 260px;
	}
	.weekly-hot-deal-img img {
    width: 100%;
	}
	.weekly-hot-deal-img {
    margin-left: 10px;
	}
	.review {
    padding: 109px;
    width: 500px;
    height: 500px;
    padding-top: 87px;
  }
  .review-style img.girl {
    top: 36%;
    left: 5%;
    width: 36%;
	}
	.review-slider.owl-carousel .owl-nav.disabled {
    top: 79%;
	}
	.heading.two h2{
		font-size: 46px;
	}
	.balloon-shap {
    width: 254px;
    height: 254px;
    margin: auto;
    margin-bottom: 14px;
	}
	.balloon-slider.owl-carousel .owl-item img {
    width: 128px;
	}
	.balloon-shap h5 {
    font-size: 16px;
  }
  .call-us {
    padding: 20px;
  }
  .call-us img {
    width: 100px;
    margin-right: 30px;
	}
	.we-provide i {
    width: 80px;
    height: 80px;
    padding: 18px;
  }
  h4 {
    font-size: 22px;
	}
	.theme-img img {
    width: 100%;
	}
	.popular-occasion .nav-link {
    padding: 24px 24px;
  }
  .theme-text h3 {
    font-size: 26px;
  }
	.theme-text ul {
    padding-bottom: 16px;
  }
  footer .logo-footer p {
    width: 90%;
  }
  .call-us a {
    font-size: 28px;
	}
	.featured-area {
    width: 540px;
    height: 540px;
    padding-top: 135px;
  }
  .featured-area.two h2 {
    font-size: 42px;
  }
  .blog-post img {
    width: 100%;
	}
	.blog-post h3{
    font-size: 24px;
	}
	.p-60 {
    padding: 40px 0;
	}
}
@media(max-width: 1200px){
	.call-us {
    padding: 50px;
    width: 52%;
  }
  .call-us.two img {
    width: 27%;
	}
  header {
    padding-top: 10px;
    padding-bottom: 10px;
	}
  .center-ber{
  	margin-top: 0;
  	padding: 0;
  }
  .error {
    width: 70%;
	}
  .ready-text {
    margin-left: 50px;
	}
  .top-bar,.nav-bar,.center-ber form,.login-bar{
  	display: none !important;
  }
  .bar-menu{
    display: block;
	}
  .weekly-sellers-img img{
  	width: auto;
  }
  .weekly-sellers-text h5 {
    font-weight: bold;
    padding-bottom: 10px;
    width: 100%;
    font-size: 18px;
	}
	.ready-time h3 {
    width: 100%;
  }
  .occasion-details .theme-text.party-balloons {
    padding-top: 0px;
    width: 100%;
	}
  .occians-holidays.for:before {
    top: 70%;
	}
	.products .nice-select.Advice {
    width: 218px;
  }
  .please-note {
    padding: 20px;
    margin-top: 10px;
	}
  .products h6 {
    font-size: 16px;
	}
	.pd-main-img img {
    width: 272px;
	}
	.price {
    margin-top: 20px;
	}
	.product-info h2 {
    font-size: 26px;
  }
  .grid-list img {
    width: 160px;
	}
	.occians-holidays.for:after {
    top: 97%;
	}
	.holidays-text li{
		font-size: 14px;
	}
	.payment-methods img {
    width: 100%;
	}
	.holidays-text {
    padding: 0px 50px;
    padding-right: 0;
}
  .banner h2 {
    padding-bottom: 40px;
	}
  .contact-style-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
	}
	.contact-style.three {
    margin-left: 20px;
    margin-right: 0;
	}
  .cart_totals-checkout {
    padding: 25px;
  }
  .team-text {
    margin-bottom: 40px;
	}
	.posts h3 {
    font-size: 18px;
	}
	.posts {
    padding: 20px;
	}
	.posts ul.instagram-posts img {
    width: 77px;
    height: 77px;
	}
	.team-text {
    margin-bottom: 40px;
	}
	.quote-box h4 {
    font-size: 18px;
    line-height: 28px;
  }
  .occians-holidays:before {
    width: 297px;
    height: 297px;
    top: 61%;
	}
	.occians-holidays figure:before {
    width: 265px;
    height: 265px;
    left: 5%;
    top: 3%;
	}
	.party-accessories img {
    padding-right: 20px;
    width: 40%;
	}
	.party-accessories {
    padding: 20px 20px;
  }
  .review-style img.girl {
    top: 62%;
    left: 2%;
    width: 36%;
	}
	.review {
    margin-left: auto;
    margin-right: 10px;
	}
	.happy-custom .heading.two h2 {
    font-size: 38px;
	}
	.recent-product-two-img img {
    width: 219px;
	}
  p{
  	font-size: 16px;
  }
  .gap {
    padding: 100px 0;
	}
	.no-top{
	    padding-top: 0;
	}
	.no-bottom{
	    padding-bottom: 0;
	}
  .party-balloon-img {
    position: relative;
    text-align: center;
    margin-bottom: 43px;
    margin-top: 70px;
	}
	img.p-balloon {
    bottom: -20px;
    left: 8%;
	}
	.b-shap {
    left: 10%;
  }
  .holiday-text-style {
    width: 100%;
  }
  .product-style.two img {
    padding: 10px;
    width: 120px;
  }
  .product-style.two .add-to-cart{
  	bottom: 0;
  }
  .product-style span {
    padding-bottom: 10px;
	}
	.popular-occasion .nav-link {
    padding: 16px 16px;
	}
	.tab-style svg {
    width: 43px;
  }
  .theme-text {
    width: 100%;
	}
	ul.top-brands {
    flex-wrap: wrap;
	}
	ul.top-brands li {
    margin-bottom: 16px;
	}
	.footer-contact-info {
    padding: 25px 30px;
  }
}
@media(max-width: 993px){
	.gap {
    padding: 80px 0;
	}
	.no-top{
	    padding-top: 0;
	}
	.no-bottom{
	    padding-bottom: 0;
	}
	.box {
    padding: 40px;
	}
	.p-auto {
    padding: 0 !important;
	}
	.box.register {
    margin-top: 30px;
	}
	.box input {
    height: 60px;
  }
   .box h3 {
    font-size: 26px;
    margin-bottom: 18px;
	}
	.call-us.two img{
		width: auto;
	}
	form.contact-form .col-lg-6 {
    padding: 0;
	}
	.call-us.two {
    margin-top: 10px;
	}
	.error-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
	.error {
    width: 93%;
	}
	.gap.weekly-section {
    padding-top: 140px;
	}
	.hero-section-two::before {
    height: 442px;
    width: 95%;
    top: 61%;
	}
	.hero-section .featured-area {
     padding-right: 25px; 
    padding-left: 25px; 
    text-align: center;
    padding-top: 140px;
	}
	.featured-area h5 {
    font-size: 18px;
	}
	.mobile-nav{
		width: 50%;
	}
	.list .recent-product-two-img img {
    width: 100%;
	}
	.weekly-sellers {
    position: relative;
    padding: 20px 25px;
    margin-top: 0px;
    margin-bottom: 30px;
    border: 0;
    border-bottom: 1px solid #eaeaea;
	}
	.similar-occasion {
    margin-bottom: 30px;
	}
	.dropdown-text {
    padding: 6px;
    padding-left: 22px;
	}
	.dropdown-text .text {
    font-size: 20px;
  }
  .coupon-area {
    margin-top: 40px;
	}
  .three #border-pagination{
  	margin-bottom: 0;
  	margin-top: 30px;
  }
  .sort-by {
    justify-content: space-between;
	}
	.product-info {
    margin-top: 40px;
	}
	ul#border-pagination.two {
    margin-top: 30px;
    margin-bottom: 0;
	}
	.products {
    margin-top: 40px;
	}
	.products {
    display: block;
	}
	ul.specification li h6 {
    padding: 14px 24px;
    width: 200px;
    margin-right: 30px;
    font-weight: bold;
    font-size: 16px;
	}
	.holidays-text {
    padding: 0px 70px;
    padding-right: 0;
	}
	.occians-holidays.for {
    margin-bottom: 20px;
    margin-top: 0;
	}
	.our-blog #border-pagination {
    margin-top: 0;
    margin-bottom: 40px;
  }
	#border-pagination {
    margin-bottom: 40px;
  }
	.occasion {
    display: block;
  }
  .occasion > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
	}
	.occasion .theme-text {
    width: 100%;
    margin-left: 0;
	}
	.contact-style-text{
		display: block;
	}
	.cart_totals-checkout {
    margin-top: 30px;
	}
	.contact-style.three {
    margin-left: 60px;
    margin-right: 0;
    margin-top: 20px;
	}
	.fun-facts-two.two {
    margin-top: 50px;
	}
	.mt-80 {
    margin-top: 50px;
	}
	form.add-review.leave-comment.comment {
    margin-bottom: 40px;
	}
	.posts ul.instagram-posts img {
    width: 209px;
    height: 209px;
	}
	.happy-custom .mt-80 {
    margin-top: 0;
	}
	footer.two .account {
    margin-top: 20px;
	}
	.out-of{
		padding-top: 0;
	}
	.review-style img.girl {
    top: 43%;
  }
	.occians-holidays figure:before{
		display: none;
	}
	.occians-holidays {
    margin-top: 0px;
	}
	.theme-text.two {
    margin-top: 0;
    margin-bottom: 40px;
	}
	.count-style {
    text-align: center;
    margin-bottom: 30px;
	}
	.blog-post {
    margin-bottom: 40px;
	}	
	.call-us {
    padding: 50px;
    width: auto;
	}
	.party-balloons h2 {
    font-size: 40px;
  }
	.party-balloon-img img {
    width: 100%;
	}
	img.p-balloon {
    left: -3%;
    width: auto;
	}
	.b-shap{
		display: none;
	}
	.account {
    margin-bottom: 30px;
	}
	.we-provide {
    margin-bottom: 30px;
	}
	.holiday-text-style {
    margin: 40px 0;
	}
	.footer-contact-info {
    margin-top: 30px;
  }
  .footer-bottom {
    flex-wrap: wrap;
	}
	.footer-contact-info {
    flex-wrap: wrap;
	}
	.leave-comment textarea {
    width: 100%;
  }
	.footer-contact-info a {
    padding-bottom: 21px;
    display: block;
	}
	.social-media {
    margin-top: 20px;
	}
	.nav-pills .nav-link {
    margin-bottom: 10px;
	}
	.theme-text {
    margin-top: 40px;
	}
	.theme-img img {
    width: auto;
    height: auto;
	}
	ul.top-brands {
    margin-bottom: 0px; 
	}
	ul.top-brands {
    justify-content: center;
  }
  .pd-main-img {
    height: 340px;
	}
}
@media(max-width: 768px){
	.theme-img img {
    width: 100%;
    height: auto;
	}
	.blog-post {
    margin-bottom: 20px;
	}
	ul.top-brands li {
    margin-left: 10px;
    width: 45%;
	}
	ul.top-brands li img {
		width: 100%;
	}
	.error-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .grid-list-text {
    text-align: inherit;
	}
	.grid-list-text ul {
    justify-content: unset;
	}
  .grid-list {
    display: block;
  }
	.error {
    width: 100%;
    padding-top: 70px;
    height: 540px;
	}
	.grid-list ul.color {
    margin-top: 10px;
	}
	.add-to-cart.two {
    padding-top: 0px;
	}
	.error h2 {
    font-size: 138px;
    line-height: 138px;
  }
	.hero-section .featured-area {
    padding-top: 112px;
    padding-right: 10px;
	}
	.featured-area h3 {
    font-size: 64px;
    padding-bottom: 10px;
	}
	.occians-holidays.for:before,.occians-holidays.for:after{
		display: none;
	}
	.occians-holidays.for {
    display: flex;
    align-items: center;
	}
	.occians-holidays.for:hover h4, .occians-holidays.for:hover h5, .occians-holidays.for:hover ul li{
		color: black;
	}
	.holidays-text {
    padding: 0px;
  }
  .occians-holidays.for {
    margin-bottom: 0px;
    padding-bottom: 0;
	}
	.occians-holidays.for img {
    width: 70%;
	}
	.banner {
    padding-bottom: 81px;
	}
	.posts ul.instagram-posts img {
    width: 150px;
    height: 150px;
	}
	.call-us.two {
    justify-content: center;
	}
	.our-blog .blog-post{
		padding-bottom: 0;
	}
	.about-the-theodore {
    display: block;
	}
	.about-the-theodore img{
		width: 100px;
	}
	.single-comment img {
    border-radius: 20px;
    margin-left: -30px;
    width: 100px;
    margin-right: 20px;
	}

	li.single-comment span{
		font-size: 14px;
	}
	li.single-comment h4 {
    font-size: 18px;
	}	
	#countdown {
    left: 10px;
	}
	#countdown h6,#countdown li,#countdown li span {
    font-size: 14px;
  }
	.occians-holidays.two,.occians-holidays.three {
    margin-top: 30px;
	}
	.featured-area {
    width: 480px;
    height: 480px;
    padding-top: 135px;
	}
	.footer-two-contact h6 {
    margin-bottom: 20px;
    margin-top: 30px;
	}
	.review {
    padding: 63px;
    width: 410px;
    height: 410px;
    padding-top: 65px;
    padding-left: 100px;
	}
	.review .star i {
    font-size: 20px;
    padding-right: 6px;
	}
	.review p {
    padding-top: 20px;
    padding-bottom: 30px;
	}
	.featured-area.two h2 {
    font-size: 36px;
	}
	.btn {
    padding: 14px 40px;
  }
	footer .logo-footer p {
    width: 100%;
    padding-bottom: 20px;
	}
	.footer-bottom img {
    width: 100%;
	}
}
@media(max-width: 576px){
	.gap {
    padding: 60px 0;
	}
	.no-top{
	    padding-top: 0;
	}
	.no-bottom{
	    padding-bottom: 0;
	}
	.weekly-section .weekly-sellers-img,.weekly-section .weekly-sellers-text {
    text-align: center;
	}
	.weekly-section .weekly-sellers-text .star {
    justify-content: center;
  }
  .weekly-sellers .add-to-cart {
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    top: 60%;
    width: 100%;
	}
	.weekly-section .weekly-sellers {
    margin-bottom: 30px;
	}
	.weekly-sellers:hover .add-to-cart {
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    top: 50%;
	}
	body .loader {
    left: 44%;
  }
	form.contact-form {
    padding: 0px 10px;
	}
	form.blog input[type="text"] {
    height: 56px;
  }
	.footer-contact-info {
    display: block;
	}
	.error {
		padding-right: 20px;
    width: 97%;
    height: auto;
    padding-left: 20px;
    margin: 0 auto;
	}
	.hero-section-two::before {
    top: 68%;
	}
	.footer-bottom-text {
    padding-bottom: 20px;
	}
	.featured-area-two h6 {
    font-size: 18px;
  }
	.addres {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 26px;
	}
	.hero-section-two::before {
    height: 330px;
    width: 95%;
	}
	.featured-area-two {
    width: 100%;
	}
	.hero-section .featured-area {
    width: 450px;
    height: 450px;
    margin: auto;
    margin-bottom: 120px;
    margin-top: 30px;
	}
	.slider-home-1 .owl-dots {
    position: absolute;
    bottom: -24px;
    right: 36%;
	}
	.mobile-nav{
		width: 100%;
	}
	.list .recent-product-two-img img {
    width: auto;;
	}
	.contact-style.three {
    margin-left: 0;
    margin-right: 0;
	}
	.store-locations {
    display: block;
  }
  .mapouter {
    width: 100%;
    height: 300px;
	}
	.store-locations-text {
    padding: 30px;
	}
	.content-location span {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    font-size: 12px;
	}
	.single-comment {
    display: block;
    width: 100%;
  }
  .single-comment {
    padding: 20px;
	}
  .single-comment img {
    margin-left: 0px;
    margin-bottom: 20px;
    width: 100px;
    margin-right: 0;
	}
	.posts ul.instagram-posts img {
    width: 120px;
    height: 120px;
	}
	.weekly-hot-deal-img img {
    width: 100%;
    height: 300px;
    margin-bottom: 10px;
	}
	.fun-facts-two, .fun-facts-two.two {
    margin: auto;
    margin-bottom: 30px;
	}
	.happy-custom .heading.two h2 {
    font-size: 30px;
	}
	.fun-facts-two.two {
    margin: auto !important;
    margin-bottom: 30px !important;
	}
	.fun-facts-two, .fun-facts-two.two {
    margin: auto !important;
    margin-bottom: 30px !important;
	}
	footer.two .footer-bottom {
    padding-top: 20px;
	}
	.review-style img.girl{
		display: none;
	}
	.balloon-slider button.owl-dot, .product-slider button.owl-dot, .feedback-slider button.owl-dot, .weekly-slider button.owl-dot {
    width: 26px;
  }
  .out-of .star i {
    font-size: 24px;
    padding-right: 2px;
	}
  .review {
    padding: 63px;
    width: 410px;
    height: 410px;
    padding-top: 80px;
    padding-left: 65px;
	}
  .heading {
    text-align: center;
    padding-bottom: 24px;
	}
	.party-balloons h2,.holiday-text-style h2 {
    font-size: 32px;
	}
	img.p-balloon {
    width: 60%;
	}
	.call-us {
    padding: 40px;
    width: auto;
	}
	.accordion {
    margin-bottom: 40px;
	}
	.party-balloon-img {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
	}
	.banner {
    padding-bottom: 50px;
	}
	.heading.two h2 {
    font-size: 40px;
	}
	.weekly-hot-deal-img {
     margin-left: 0px; 
	}
}
@media(max-width: 500px){
	.featured-area {
    width: 400px;
    height: 400px;
    padding: 40px;
    padding-top: 90px;
	}
	.hero-section-two::before {
    height: 281px;
    width: 95%;
	}
	.recent-product-two-img img {
    width: 160px;
    padding: 20px;
	}
	ul.reviews li {
    display: block;
	}
	ul.reviews li img {
    margin-right: 0;
    margin-bottom: 24px;
	}
	.pd-gallery {
    display: block;
	}
	ul.pd-imgs {
    display: flex;
	}
	ul.reviews li{
		position: relative;
	}
	ul.reviews li .star {
    position: absolute;
    left: 0;
    top: 33%;
	}
	.holidays-text {
    width: 100%;
	}
	.occians-holidays.for img {
    width: 90%;
    margin-right: 50px;
	}
	.featured-area.two h2 {
    font-size: 28px;
	}
	.featured-area:before {
    width: 88px;
    height: 40px;
  }
  .title {
    padding-left: 45px;
	}
	.content {
    padding: 0px 45px;
    padding-bottom: 10px;
    padding-right: 0px;
	}
	.accordion-item h6 {
    font-size: 12px;
  }
  ul.instagram-posts img {
    height: 110px;
    width: 111px;
	}
  .title {
    font-size: 16px;
	}
  h2 {
    font-size: 40px;
	}
  .featured-area.two .btn {
    padding: 14px 30px;
    font-size: 12px;
	}
  .featured-area.two p {
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 26px;
	}
	.cart_totals-checkout{
		padding: 30px;
	}
	.description.nav-pills .nav-link, .description.nav-pills .show>.nav-link {
    padding: 18px;
  }
  .description {
    width: 328px;
  }
}
@media(max-width: 480px){
	.product-style.two {
    display: block;
  }
  .error h2 {
    font-size: 88px;
    line-height: 86px;
	}
	.heading.two h2 {
    font-size: 34px;
	}
	.we-provide {
    margin-bottom: 20px;
	}
	.error h3 {
    font-size: 30px;
  }
  .error {
    padding-bottom: 54px;
	}
  .package-includes {
    padding: 30px;
    padding-top: 60px;
   }
   .error:before {
    width: 84px;
  }
  .hero-section-two::before {
    height: 360px;
	}
  .hero-section .featured-area {
    width: 390px;
    height: 390px;
    margin: auto;
    margin-bottom: 110px;
    margin-top: 30px;
    padding-top: 90px;
	}
  ul.pd-imgs li a {
    width: 80px;
    height: 80px;
  }
  .featured-area h5 {
    font-size: 14px;
    padding: 16px;
	}
	.featured-area h3 {
    font-size: 50px;
    padding-bottom: 0px;
	}
	.featured-area p {

    font-size: 16px;
	}
  .featured-area span {
    font-size: 14px;
  }
  .occasion > img {
    height: auto;
  }
  .theme-text .star{
  	margin-left: 10px;
  }
  .theme-text h3 {
    font-size: 22px;
	}
  .theme-text .star i{
  	font-size: 10px;
  }
  .content-location p {
    width: 100%;
    padding-bottom: 10px;
    font-size: 14px;
	}
	.content-location a {
    font-size: 14px;
	}
  form.add-review {
    padding: 30px;
  }
  .quote-box h4 {
    font-size: 16px;
    line-height: 26px;
	}
	.blog-post .holidays-text li {
    font-size: 14px;
	}
  .leave-comment input {
    height: 54px;
  }
  .leave-comment textarea {
    width: 100%;
    margin: auto;
    height: 150px;
    margin-bottom: 20px;
  }
  ul.social-media li a {
    width: 40px;
    height: 40px;
    font-size: 13px;
  }
  .quote-box {
    padding: 30px;
  }
  ul.social-media li {
    margin-left: 5px;
	}
	.share-post h5 {
    font-weight: bold;
    padding-right: 24px;
	}
	.about-the-theodore h3 {
    font-size: 20px;
	}
  .comment h3 {
    font-size: 24px;
  }
  .posts ul.instagram-posts img {
    width: 100px;
    height: 100px;
	}
  .party-balloons ul li {
    font-size: 16px;
  }
  .decoration-ideas h4 {
    font-size: 30px;
    font-weight: bold;
	}
}
@media(max-width: 450px){
	.featured-area {
    width: 360px;
    height: 360px;
    padding: 40px;
    padding-top: 80px;
	}
	.quote-box {
    margin-bottom: 30px;
  }
	.blog-post .holidays-text {
    margin-left: 0;
	}
	.box {
    padding: 30px;
	}
	.breadcrumb {
    padding-top: 30px;
	}
	form.checkout-meta input {
    height: 55px;
  }
  form.checkout-meta.donate-page .nice-select.Advice {
    height: 55px;
    padding-top: 7px;
  }
  .woocommerce-additional-fields textarea.input-text {
    height: 160px;
    padding: 15px;
  }
	.recent-product-two-img {
    margin-bottom: 10px;
  }
  .recent-product-two {
    margin-bottom: 30px;
	}
	.button{
		margin-top: 10px;
	}
	.theme-text.two del {
    font-size: 16px;
  }
  .party-accessories {
    margin-bottom: 20px;
  }
	.theme-img img {
    width: 100%;
    height: 240px;
	}
	.account h5 {
    padding-bottom: 12px;
    padding-top: 12px;
	}
	.theme-text.party-balloons a.btn {
    margin-top: 10px;
		}
	.account h5 {
    padding-bottom: 12px;
    padding-top: 12px;
	}
	.contact-form input {
    font-size: 14px;
    height: 54px;
    margin-bottom: 10px;
    padding-left: 20px;
	}
	.contact-form textarea {
    height: 120px;
    padding-top: 14px;
    margin-bottom: 20px;
    padding-left: 20px;
    font-size: 14px;
	}
	.products .nice-select.Advice {
    width: 180px;
	}
	.sort-by {
    flex-wrap: wrap;
	}
	.breadcrumb li a, .breadcrumb-item.active {
    font-size: 12px;
    line-height: 12px;
	}
	.products #v-pills-tab {
    margin-left: 0px;
	}
	li.breadcrumb-item.active {
    line-height: 2;
    padding-top: 2px;
	}
	.party-balloons h4 {
    font-size: 26px;
	}
	.theme-text {
    margin-top: 26px;
	}
	.please-note h5 {
    font-size: 16px;
	}
	.please-note p {
    font-size: 14px;
    line-height: 24px;
	}
	.breadcrumb-item+.breadcrumb-item::before {
    padding-right: 10px;
	}
	.breadcrumb-item+.breadcrumb-item {
    padding-left: 10px;
	}
	.featured-area span {
    font-size: 14px;
    letter-spacing: 0;
	}
	.featured-area p {
    font-size: 14px;
    width: 90%;
    line-height: 24px;
	}
	.party-balloons p {
    line-height: 26px;
	}
	.nav-link {
    padding: 5px 18px;
    font-size: 13px;
	}
	.hero-section .featured-area {
    width: 340px;
    height: 340px;
    margin: auto;
    margin-bottom: 110px;
    margin-top: 30px;
    padding-top: 59px;
	}
	.product-info {
    margin-top: 30px;
    padding: 25px;
	}
	.product-info h2 {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 6px;
	}
	.product-info .add-to-cart {
    position: relative;
    opacity: 1;
    bottom: 0;
    margin-top: 10px;
    margin-bottom: 15px;
	}
	.quantity  input.input-text {
    width: 100%;
    margin-left: 20px;
    height: 50px;
  }
  .price h3 {
    font-size: 30px;
    font-weight: bold;
	}
	.price del {
    color: #666;
    font-weight: 400;
    padding-top: 12px;
    font-size: 14px;
    margin-left: 10px;
	}
	.product-info .add-to-cart {
    position: relative;
    opacity: 1;
    bottom: 0;
    margin-top: 10px;
    margin-bottom: 15px;
	}
	ul.specification li h6 {
    padding: 14px 24px;
    width: 160px;
    margin-right: 20px;
    font-weight: bold;
    font-size: 14px;
	}
	ul.specification li {
    font-size: 14px;
	}
	.party-balloons ul li {
    font-size: 14px;
	}
	.payment-methods {
    margin-top: 20px;
	}
	.occians-holidays.for {
    display: block;
	}
	.holidays-text {
    width: auto;
    margin-bottom: 20px;
    margin-left: 20px;
	}
	.review {
    padding: 63px;
    width: 360px;
    height: 360px;
    padding-top: 48px;
    padding-left: 64px;
	}
	.review p {
    font-size: 14px;
    line-height: 24px;
	}
	h3 {
    font-size: 22px;
	}	
	.call-us {
    padding: 24px;
  }
  .call-us p {
    font-size: 14px;
	}
	.call-us a {
    font-size: 24px;
	}
	.call-us img {
    width: 88px;
    margin-right: 24px;
	}
	.featured-area:before {
    width: 70px;
    height: 30px;
	}
	.footer-two-contact h6 {
    margin-bottom: 10px;
  }
  footer.two .account h5 {
    padding-bottom: 10px;
  }
  .links-footer {
    border-top: 3px solid #c9c9c9;
    margin-top: 40px;
    padding-top: 40px;
	}
	footer.two .social-media {
    margin-top: 20px;
	}
	footer.two .footer-bottom {
    padding-top: 10px;
	}
	footer.two .account {
    margin-top: 10px;
	}
	.occians-holidays.for img {
    width: 90%;
     margin-right: 0px; 
	}
	.account ul li:not(:last-child) {
    padding-bottom: 8px;
	}
}
@media(max-width: 410px){
	h2 {
    font-size: 34px;
	}
	.hero-section-two::before {
   top: 64%;
	}
	.featured-area-two h6 {
    font-size: 16px;
  }
	.heading.two h2 {
    font-size: 34px;
	}
	.weekly-hot-deal-img img {
   	height: 200px;
    
	}
	ul.links,ul.categories {
    margin-top: 20px;
	}
	.posts.subscribe p{
		padding-top: 20px;
	}
	.posts ul.instagram-posts img {
    width: 90px;
    height: 90px;
	}
  .review h5 {
    font-size: 20px;
  }
  .review {
    width: 330px;
    height: 321px;
    padding: 40px;
    padding-top: 48px;
    padding-left: 66px;
	}
  
	.review p {
    padding-bottom: 16px;
	}
	.review:after {
    width: 56px;
  }
  .blog-post h3 {
    font-size: 22px;
	}
	.holiday-text-style h2,
	.party-balloons h2 {
    font-size: 30px;
	}
	h2 {
    font-size: 32px;
	}
}
@media(max-width: 375px){
	.featured-area.two h2 {
    font-size: 24px;
    padding-bottom: 10px;
	}
	.banner h2 {
    padding-bottom: 20px;
		}
	.breadcrumb {
    padding-bottom: 10px;
	}
	.video i {
    width: 70px;
    height: 70px;
  }
  .blog-img-video i svg {
    width: 12px;
  }
	.hero-section-two::before {
    height: 355px;
    top: 67%;
	}
	.video i:after {
    width: 66px;
    height: 66px;
  }
	.featured-area-two h6 {
    font-size: 16px;
  }
	.product-info .add-to-cart a.btn {
    width: 160px;
    margin-right: 10px;
	}
	ul.specification li h6 {
    padding: 14px 20px;
    width: 140px;
    margin-right: 14px;
    font-size: 14px;
	}
	.call-us span {
    font-size: 14px;
	}
	.featured-area.two .btn {
    padding: 8px 24px;
	}
	.btn span {
    font-size: 14px;
	}
	.featured-area {
    width: 300px;
    height: 300px;
    padding: 40px;
    padding-top: 56px;
	}
	.customer-feedback:before {
    width: 90%;
  }
  .customer-feedback > i {
    width: 60px;
    height: 60px;
  }
  .feedback-slider.owl-carousel .owl-item img {
    width: 22px;
	}
	.featured-area.two p {
    padding-bottom: 20px;
    line-height: 22px;
    width: 100%;
	}
	.customer-feedback > i {
    margin-bottom: 20px;
  }
	.customer-feedback > i:after {
    width: 12px;
    height: 4px;
    bottom: -3px;
	}
  h2 {
    font-size: 30px;
	}
	.customer-feedback p {
    width: 96%;
    padding-bottom: 16px;
    line-height: 26px;
	}
	.mobile-nav {
    padding: 50px 30px 15px;
  }
	.heading.two h2{
		font-size: 30px;
	}
	.logo img {
    width: 80%;
	}
	footer .logo-footer p {
    padding-top: 20px;
 	}
 	table.shop_table td.product-quantity input {
    width: 100px;
   }
 	.account ul li:not(:last-child) {
    padding-bottom: 10px;
	}
	table.shop_table td.product-price {
    padding: 10px;
	}
	.ready-time h3 {
    font-size: 22px;
    line-height: 32px;
	}
	.hero-section-two::before {
    height: 290px;
  }
	.ready-text {
    margin-left: 30px;
	}
	p {
    font-size: 16px;
    line-height: 24px;
	}
	#v-pills-tab {
    margin-bottom: 20px;
	}
	.review .star{
		justify-content: center;
	}
	.blog-post p {
    font-size: 16px;
    line-height: 26px;
  }
	.review {
    padding: 50px;
    padding-top: 50px;
    padding-left: 40px;
    text-align: center;
	}
	.featured-area-two h6 {
    padding-top: 10px;
    font-size: 22px;
    padding-bottom: 20px;
	}
} */