/* BODY */
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

body {
    font-family: 'Ubuntu Sans';
    overflow-x: hidden;
    font-weight: bold;
}

body {

    /* background-image: url(../assets/img/bg1.png); */
    overflow-y: auto;
}

body.page-loaded {
    overflow-y: auto;
}

body .loader {
    z-index: 1111111111111;
    left: 48%;
    --loader-size: 50px;
    --loader-border-size: 4px;
    --loader-border-color: white;
    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-border-size) solid white;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
    border-radius: 50%;
    position: fixed;
    animation: rotateX 1s infinite linear;
    top: 45%;
    transform: translate(-50%, -50%);
}

body.page-loaded .loader {
    display: none;
}

.loader::before {
    content: "";
    border: var(--loader-border-size) solid var(--loader-border-color);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    animation: rotateX 0.5s infinite linear reverse;
}

* {
    margin: 0;
    padding: 0;
}

.header {
    color: white;
    font-family: 'Ubuntu Sans';
    /* overflow-x: hidden; */
    padding-top: 10px;
}

.header-bg {
    background-color: #672b83;
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-bar .p {
    font-size: 18px;
    line-height: 30px;
    color: #555;
    margin: 0;
    font-weight: 400;
    font-family: 'Ubuntu Sans';
}

.ordering {
    display: flex;
    align-items: center;
}

.ordering .line {
    display: block;
    width: 1px;
    height: 12px;
    background: white;
    margin: 0px 20px;
}

.ordering a {
    color: white;
    font-family: 'Ubuntu Sans';
    font-weight: 400;
    font-size: 14px;
}

.redt {
    color: #ff3394;
}

.title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0;
    font-weight: normal;
}

.donation a.pr-cart svg {
    width: 25px;
}

.center-ber {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;
    padding-bottom: 26px;
}

.center-ber form {
    position: relative;
}

.center-ber form button {
    position: absolute;
    background-color: pink;
    color: wheat;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    outline: none;
}

.center-ber form input {
    width: 720px;
    height: 50px;
    padding-left: 30px;
    border-radius: 51px;
    color: black;
    border: 3px solid #9978a9;
    outline: none;
    background-color: transparent;
}

.center-ber form input::placeholder {
    color: white;
}

.center-ber form button {
    position: absolute;
    background-color: #ff3394;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    outline: none;
    border: 0;
    right: 0;
}

.center-ber a {
    color: white;
    display: flex;
    text-transform: capitalize;
    font-size: 18px;
}

.center-ber svg {
    fill: white;
    margin-left: 14px;
    width: 23px;
    height: auto;
}

.center-ber svg {
    fill: white;
    margin-left: 14px;
}

.donation {
    margin-left: 20px;
    position: relative;
}

a {
    text-decoration: none;
}

.nav-bar {
    background-color: white;
}

.bar-menu {
    display: none;
}

.mobile_h {
    display: none;
}

.bar-menu i {
    color: white;
    font-size: 24px;
}


/* Mobile navbar */
.mobile-nav {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background-color: white;
    padding: 50px 40px 15px;
    z-index: 5;
    transition: 0.25s ease-in-out;
    transform: translateX(-101%);
    -webkit-transform: translateX(-101%);
    -moz-transform: translateX(-101%);
    -o-transform: translateX(-101%);
    overflow-y: auto;
}

.mobile-nav ul li a svg {
    width: 11px;
}

.for #nav-icon4 {
    color: black;
}

#nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 13px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.responsive-bar {
    display: none;
}

#nav-icon4 span:nth-child(3) {
    top: 25px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
}

#nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 35px;
    left: 8px;
}

i#nav-icon4 {
    display: none;
}

#nav-icon4 {
    width: 40px;
    height: 25px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: none;
    font-size: 25px;
    color: white;
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.mobile-nav>ul>li.menu-item-has-children:before {
    content: "";
    background-image: url(../../images/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 25px;
    right: 0;
    width: 10px;
    height: 10px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.mobile-nav>ul>li.menu-item-has-children.active:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.mobile-nav>ul>li {
    position: relative;
    border-bottom: 0px solid #d4d4d4;
    padding: 10px 0;
    display: block;
}

.mobile-nav>ul>li:not(:last-child) {
    border-bottom: 1px solid #d4d4d4;
}

.mobile-nav>ul li>a {
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
}

.mobile-nav>ul>li>a {
    font-size: 16px;
    line-height: 30px;
}

.mobile-nav>ul>li.menu-item-has-children>ul.sub-menu {
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 5px;
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(15px);
    -webkit-transform: translateX(15px);
    -moz-transform: translateX(15px);
    -o-transform: translateX(15px);
    border-top: 1px solid #d4d4d4;
    margin-top: 8px;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu {
    position: relative;
    height: auto;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transition-delay: 0.1s;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu li {
    padding: 5px 0;
    position: relative;
}

.res-log img {
    width: auto;
}

.mobile-nav>ul>li.menu-item-has-children.active>ul.sub-menu li:before {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: -13px;
}

.res-log {
    margin-bottom: 30px;
}

.mobile-nav .donation {
    display: flex;
    align-items: center;
    margin: 25px 0;
}

.mobile-nav .donation a:first-child {
    margin-right: 40px;
}

.mobile-nav .donation a.theme-btn {
    padding: 10px 30px;
}

.mobile-nav .donation a.cart {
    position: relative;
}

.mobile-nav .donation a.cart:before {
    content: "1";
    color: white;
    width: 25px;
    height: 25px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    top: -7px;
    right: -19px;
    z-index: 1;
    font-size: 13px;
}

.res-rights p {
    color: #d6d6d6;
    font-weight: bold;
    letter-spacing: 5px;
    margin-top: 30px;
}

.mobile-nav.open {
    transform: translateX(0) !important;
    z-index: 33333333;
    box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 39px -4px rgba(0, 0, 0, 0.75);
}

li.menu-item-has-children.active {
    background-color: transparent;
}

.mobile-nav a#res-cross:before {
    content: "";
    width: 35px;
    height: 35px;
    /* background-image: url('../img/remove.svg'); */
    background-repeat: no-repeat;
    position: absolute;
    top: 15px;
    right: 20px;
}

.bar-menu i {
    color: black;
    background-color: transparent;
    font-size: 24px;
}

.responsive-bar-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.bar-menu {
    display: none;
}

.bar-menu i {
    color: white;
}





/* 3. navbar */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
    opacity: 0;
    transition: 1s all;
}

.navbar {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
}

.navbar-logo {
    color: #ff3f34;
    text-decoration: none;
    font-size: 25px;
    padding: 0px 20px;
}

.navbar-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    padding: 20px 30px;
    padding-left: 0;
    padding-right: 70px;
}

.navbar-links li a {
    display: block;
    text-decoration: none;
    color: #707070;
    transition: 0.4s all;
    padding-left: 0;
}

nav.navbar>a svg {
    width: 28px;
    height: auto;
    margin-right: 5px;
    fill: #ff3394;
}

nav.navbar>a {
    color: #ff3394;
}

nav.navbar a.phone {
    padding-left: 34px;
    border-left: 1px solid #b9b9b9;
    height: 40px;
    padding-top: 6px;
}

li.menu-item-children>a {
    position: relative;
}

li.menu-item-children>a:before {
    content: "";
    background-image: url(../../images/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    right: -18px;
    width: 10px;
    height: 10px;
}

.navbar-links li.navbar-dropdown {
    position: relative;
}

.navbar-links li.navbar-dropdown:hover .dropdown-additional {
    border-radius: 15px;
    visibility: visible;
    opacity: 1;
    transform: translatex(0px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: left;
    position: absolute;
}

.navbar-links li.navbar-dropdown .dropdown-additional {
    z-index: 1111;
    visibility: hidden;

    opacity: 0;
    position: absolute;
    top: 100%;
    transform: translatex(50px);
    left: 0;
    width: 230px;
    background-color: #fff;
    box-shadow: 0px 10px 10px 3px hsl(0deg 0% 0% / 8%);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 111;
    transition: 0.4s all;
    text-align: left;
}

.navbar-links li.navbar-dropdown .dropdown-additional a {
    padding-left: 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-weight: 400;
    border: 0;
    position: relative;
    color: #222;
}

.navbar-links li.navbar-dropdown .dropdown-additional a:hover {
    color: #ff3394;
}

.navbar-links li.navbar-dropdown .dropdown-additional a:not(:last-child):before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: #58585829;
    width: 75%;
    bottom: 0;
}

.navbar-links li:hover>a {
    border-bottom: 3px solid #f6ff00;
}

.navbar-links li>a {
    border-bottom: 5px solid transparent;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    font-size: 18px;
}

li.navbar-dropdown.menu-item-children img {
    width: 18px;
    margin-bottom: 4px;
}

.navbar-links li>a i {
    padding-right: 6px;

}


/* ------------------------- */





h1 {
    font-size: 60px;
}

h2 {
    font-size: 60px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

img {
    object-fit: cover;
}

.no-top {
    padding-top: 0;
}

.no-bottom {
    padding-bottom: 0;
}