.offcanvas  {
    width: 33% !important;
}

.offcanvas-body {
    display: flex;
    flex-direction: column; 
    align-items: left; 
}

.search-section {
    margin: 1rem 0; /* Приклад відступів */
  }
  
  .search-section input {
    width: 80%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button {
    padding: 7px;
    margin-left: 5px;
    border: 1px solid #ccc;
    border-radius: 10%;
  }

  .support-section {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    font-size: 1rem;
  }
  .support-text {
    text-decoration: none;
    color: #67696a;
  }
  .support-text a {
    text-decoration: none;
    color: #878788;
  }
  
  .support-text a:hover {
    text-decoration: underline;
    color:blueviolet;
  }