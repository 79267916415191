.footer {
    background-color: #3A0A51;
    height: 667px;
}

.footer .logo-footer p {
    color: #e4e4e4;
    font-size: 16px;
    padding-top: 30px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
}

.footer-contact-info {
    line-height: 30px;
    border-radius: 10px;
    margin-top: 60px;
    display: flex;
    align-items: center;
    padding: 25px 70px;
    justify-content: space-between;
    background-color: #672b83;
}

.footer-contact-info svg {
    padding-top: 12px;
}

.footer-contact-info p {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

.footer-contact-info a,
.footer-contact-info span {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}

.footer-contact-info i {
    color: #f6ff00;
    font-size: 30px;
    padding-right: 20px;
}

.footer-contact-info i svg {
    width: 36px;
    height: auto;
    fill: #f6ff00;
}

.footer-bottom {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom p {
    color: white;
    padding-top: 14px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
}

.footer-bottom span {
    color: #ff3394;
}

.social-media {
    display: flex;
    align-items: center;
    list-style: none;
}

ul.social-media li {
    margin-left: 10px;
}

ul.social-media li a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #4d1866;
    color: white;
}

ul.social-media li a:hover,
footer.two ul.social-media li a:hover {
    background-color: #ff3394;
    border: 3px solid #ff3394 !important;
    color: white !important;
    transform: translateY(4px);
}

.account ul li a:hover,
.links-footer .account ul li a:hover {
    color: #ff3394;
    padding-left: 6px;
}

.posts a,
.footer-two-i a,
.hidden-box ul li .bolo,
.hidden-box ul li a,
ul#border-pagination i,
.recent-posts li h6 a,
.team-text a i,
.single-comment a,
.weekly-sellers-text a h5,
#border-pagination li a,
.account button,
.btn span,
.ready-time h3,
.weekly-sellers-img,
.weekly-sellers-img img,
ul.top-brands li img,
ul.top-brands li,
.account ul li i,
.product-style h6,
.add-to-cart a.heart-wishlist,
.product-style span,
.account ul li a,
.balloon-shap,
.balloon-shap:before,
.add-to-cart,
.product-style del,
ul.instagram-posts figure:before,
.btn:before,
.cart-popup,
.balloon-shap h5,
.product-style,
.occians-holidays.for h4,
.occians-holidays.for h5,
ul.social-media li a,
.weekly-sellers,
.occians-holidays.for:after,
.blog-post h3,
.occians-holidays.for:before,
.holidays-text ul li,
.blog-post a h6,
.blog-post a i,
.product-style h5,
ul.categories li a,
.party-accessories,
.icon,
.team-text a h6,
.team-text svg,
.content-location a {
    transition: .3s ease-in-out;
}