.module-background {
    background-image: url('../../../images/bg1.png');
    min-height: 638px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.module-container {
    width: 75%;
    min-height: 500px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    /* background-color: aquamarine; */
}

@media (max-width: 768px) {

    .module-container {
        width: 80%;
        
    }
}

@media (max-width: 480px) {

    .module-container {
        width: 100%;

    }
}