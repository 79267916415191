@media (max-width: 1200px) {

    .gap {
        height: 650px;
    }

    .slider-content {
        width: 100%;
    }

    .slick-dots {
        bottom: -60px !important;
    }

    .slick-slider {
        padding-left: 50px !important;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .slider-content {
        width: 100%;
    }

    .slick-dots {
        bottom: -60px !important;
    }

    .slick-slider {
        padding-left: 100px !important;
    }

    h2 {
        font-size: 48px;
    }

    p {
        font-size: 16px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {

    .slider-content {
        width: 100%;
    }

    .slick-dots {
        bottom: -60px !important;
        left: 10px !important;
    }

    .slick-slider {
        padding-left: 25px !important;
    }

    .heading h2 {
        font-size: 48px;
    }

    .heading p {
        font-size: 16px;
    }
}

@media (max-width: 479px) {
    .lesson-slider-baloon {
        height: 600px;
        padding: 60px 0;
    }

    .slider-content {
        width: 100%;
    }

    .slick-dots {
        bottom: -60px !important;
        left: 10px !important;
    }

    .slick-slider {
        padding-left: 135px !important;
    }

    .heading h2 {
        font-size: 32px;
    }

    .heading p {
        font-size: 14px;
    }

}

@media (max-width: 400px) {
    .slick-slider {
        padding-left: 110px !important;
    }
}

@media (max-width: 375px) {
    .slick-slider {
        padding-left: 100px !important;
    }
}

@media (max-width: 320px) {
    .slick-slider {
        padding-left: 80px !important;
    }
}