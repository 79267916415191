@media (max-width: 1200px) {

    .baloon-item {
        display: inline-block;
        text-decoration: none;
      }

    .baloon-circle {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease;
      }

      .baloon-square {
        width: 110px;
        height: 110px;
      }

      .baloon-circle p {
        color: black;
        padding-top: 5px;
        font-size: 12px;
      }
}

@media (min-width: 768px) and (max-width: 1199px) {

  .baloon-item {
      display: inline-block;
      text-decoration: none;
    }

  .baloon-circle {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
    }

    .baloon-square {
      width: 100px;
      height: 100px;
    }

    .baloon-circle p {
      color: black;
      padding-top: 4px;
      font-size: 12px;
    }

}

@media (min-width: 480px) and (max-width: 767px) {

  .baloon-item {
      display: inline-block;
      text-decoration: none;
    }

  .baloon-circle {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
    }

    .baloon-square {
      width: 95px;
      height: 95px;
    }

    .baloon-circle p {
      color: black;
      padding-top: 4px;
      font-size: 10px;
    }

}

@media (max-width: 479px) {

  .baloon-item {
      display: inline-block;
      text-decoration: none;
    }

  .baloon-circle {
      width: 170px;
      height: 170px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
    }

    .baloon-square {
      width: 85px;
      height: 85px;
    }

    .baloon-circle p {
      color: black;
      padding-top: 4px;
      font-size: 10px;
    }

}