@media (max-width: 1200px) {

    .footer-contact-info {
        padding: 25px 30px;
    }

    .footer {
        background-color: #3A0A51;
        height: 730px;
    }

    .footer-bottom p {
        font-size: 16px;
    }

}

.footer {
    height: 767px;
}

@media (max-width: 1024px) {
    .footer {
        height: 680px;
    }

    .footer .logo-footer p {
        color: #e4e4e4;
        font-size: 14px;
        width: 83%;
        padding-top: 30px;
        line-height: 26px;
        font-weight: 400;
        margin: 0;
    }

    .footer-contact-info p {
        font-size: 12px;
    }

}

@media (min-width: 768px) and (max-width: 1023px) {
    .footer {
        height: 680px;
    }

    .footer-contact-info {
        padding: 20px 50px;
    }

    .footer-contact-info i {
        display: grid;
        place-items: center;
    }

    .footer-contact-info svg {
        padding: 0;
    }

    .footer .logo-footer p {
        color: #e4e4e4;
        font-size: 14px;
        width: 83%;
        padding-top: 30px;
        line-height: 26px;
        font-weight: 400;
        margin: 0;
    }

    .footer-contact-info p {
        font-size: 12px;
    }

    .footer.gap {
        padding: 40px;
    }

}

@media (min-width: 480px) and (max-width: 767px) {
    .footer {
        height: 680px;
    }

    .footer-mailto,
    .footer-phone-num,
    .footer-support,
    .footer-address {
        display: none;
    }

    .footer-bottom p {
        font-size: 14px;
    }

    .logo-footer {
        scale: 0.9;
    }

    .footer-contact-info svg {
        padding: 0;
    }

    .footer-contact-info i {
        padding: 0;
    }

    ul.social-media li a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #4d1866;
    }
}

@media (max-width: 479px) {
    .footer {
        height: 730px;
    }

    .footer-mailto,
    .footer-phone-num,
    .footer-support,
    .footer-address {
        display: none;
    }

    .footer-bottom p {
        font-size: 14px;
    }

    .logo-footer {
        scale: 0.7;
    }

    .footer-contact-info svg {
        padding: 0;
    }

    .footer-contact-info i {
        padding: 0;
    }

    ul.social-media li a {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #4d1866;
    }
}