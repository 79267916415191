.module-game {
  padding: 20px;
}

.game-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.instructions {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
}

.word-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.word-container>div {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Расстояние между цифрой и словом */
  padding: 5px;
  border: 1px solid #ccc;
  /* Рамка вокруг группы */
  border-radius: 8px;
  /* Закругленные углы */
  background-color: #f9f9f9;
  /* Светлый фон для выделения */
}

.draggableWord {
  padding: 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-zones {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.zone-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.drop-container {
  background-color: #FFFF00;
  width: 50%;
  display: block;
  text-align: center;
}