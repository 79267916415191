.carousel_bg {
  background-image: url('../../images/bg1.png');
  height: 640px;
}
.carousel_bg .text {
  color: black
}

.headerinfo {
display: flex;
align-items: center;
justify-content: center;
}

.slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 90%;
}
.slider img {
  display: flex;
  justify-content: flex-start; 
  padding-left: 10%; 
padding-top: 10%;
  scale: 1;
}

.custom-controls {
  display: flex;
  justify-content: center;
  position: sticky;
  /* right: 10%; 
  bottom: 7%;  */
  padding-top: 10px;
  z-index: 10;
  scale: 0.7;
}

.control-dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid gray;
}

.control-dot.active {
  background-color: #FF3394;
  color: white;
  border-color: darkred;
}