.test-container {
    width: 100%;
    max-width: 800px;
    background-color: rgba(255, 255, 255, 0.8);
    /* background: url('../../../images/sota.jpg') repeat center center; */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 20px;
}

.test-title {
    font-size: 1.5em;
    font-weight: bold;
    color: purple;
    margin-bottom: 20px;
}

.testsubtitle {
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(255, 51, 148);
    margin-bottom: 20px;
}

.test-question {
    margin-bottom: 20px;
}

.answer-options {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.answer-options li {
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    padding: 0;

}

.option-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: grey;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}

.option-circle.selected {
    background-color: yellow;
}

.option-circle.correct {
    background-color: green;
}

.option-circle.incorrect {
    background-color: red;
}

.option-circle:hover {
    background-color: white;
    color: black;
}

.test-container.btn {
    display: none;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
}

.checkAnswer {
    background-color: blue;
    color: white;
    display: inline-block;
    margin-top: 6px;
}

.checkAnswer:hover {
    background-color: blue;
    color: white;
    display: inline-block;
}

.nextAnswer {
    background-color: green;
    color: white;
    margin-top: 6px;
}

.nextAnswer:hover {
    background-color: green;
    color: white;
}

.score-counter {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: blue;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
}

.game {
    max-width: 840px;
}

.text-wow {
    font-size: 1em;
    font-weight: bold;
    font-style: italic;
    color: purple;
}

.new-wow {
    color: black;
}

.highlight {
    font-weight: bold;
    color: purple;
    font-size: 1.3em;
    font-style: italic;
}

.horizontal,
.vertical {
    /* background-color: blue; */
    width: 60%;
    height: auto;
    display: inline-block;
    vertical-align: top;
}

.vertical {
    width: 60%;
    height: 350px;
    display: inline-block;
    vertical-align: top;
}

.c2 {
    /* background-color: yellowgreen; */
    width: 40%;
    height: 200px;
    display: inline-block;
    vertical-align: top;
}

.horizontal img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;

}

.vertical img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    display: block;
}

.horizontal iframe {
    height: 200px;
}

.imgcaption {
    font-size: 0.8em;
    color: grey;
}

/* Загальні стилі для тесту */
.quiz-radio input[type="radio"] {
    display: none;
    /* Ховаємо радіо-кнопки для стилізації */
}

.quiz-radio label {
    cursor: pointer;
    padding: 5px 10px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    /* Додаємо відступ між кнопками */
}

/* Стиль для вибраної правильної відповіді */
.quiz-radio input[type="radio"]:checked.correct+label {
    background-color: green;
    color: white;
    border-color: green;
}

/* Стиль для вибраної неправильної відповіді */
.quiz-radio input[type="radio"]:checked.incorrect+label {
    background-color: red;
    color: white;
    border-color: red;
}

/* Відступ між питаннями та відповідями */
.quiz-radio div {
    margin-bottom: 15px;
    /* Менший відступ між питаннями */
}

/* Відстань між текстом питання та варіантами */
.quiz-radio p {
    margin-bottom: 8px;
    /* Питання ближче до варіантів */
}