.dropdown-task {
    display: flex;
    justify-content: space-between;
}

.text-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.dropdown-task .dropdown {
    cursor: pointer;
    background-color: #822169;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-family: 'Ubuntu Sans', sans-serif;
}

.title-question {
    padding-bottom: 10px;
}

.test-answers {
    width: "30%";
}

.answer-options-dropdown-task {
    
    list-style-type: none;
    padding: 0;
}
.answer-options-dropdown-task li {
    margin: 10px 0;
    display: flex;
    align-items: center;
}