.option-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: grey;
    color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .option-circle.selected {
    background-color: yellow;
  }
  
  .option-circle.correct {
    background-color: green;
  }
  
  .option-circle.incorrect {
    background-color: red;
  }
  
  .option-circle:hover {
    background-color: white;
    color: black;
  }
  