.baloon-item {
  display: inline-block;
  text-decoration: none;
}

.baloon-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  /* background-image: url(../../../images/book_circle_1.png); */
  background-size: contain;
}

.baloon-circle:hover {
  background-color: #672b83;
}

.baloon-square {
  width: 150px;
  height: 150px;
  text-align: center;
}

.baloon-square img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.baloon-circle p {
  color: black;
  padding-top: 5px;
  font-size: 18px;
}