.dropdown-toggle::after {
    display: none;
}

.user_dropdown {
    background-color: white;
}

.user_dropdown .dropdown-item {
    color: black;
}

.user_dropdown .dropdown-item-text {
    font-size: 15px;
}

@media (max-width: 1024px) {
    .user_dropdown {
        max-width: 170px;
    }

    .user_dropdown a {
        font-size: 14px;
    }

    .user_dropdown .dropdown-item-text {
        font-size: 14px;
    }

}

@media (max-width: 479px) {
    .user_dropdown {
        max-width: 100px;
    }

    .user_dropdown a {
        font-size: 12px;
    }

    .user_dropdown .dropdown-item-text {
        font-size: 12px;
    }

}